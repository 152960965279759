import { configureStore} from '@reduxjs/toolkit'
import RootReducer from './reducers/RootReducer'

const Store = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
  }),
})

if (process.env.NODE_ENV === 'development' && module.hot) {
     module.hot.accept('./reducers/RootReducer', () => {
       const newRootReducer = require('./reducers/RootReducer').default
       Store.replaceReducer(newRootReducer)
     })
}
  
export default Store