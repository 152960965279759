import axios from "axios"
import localStorageService from "./localStorageService";
// const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrl = process.env.REACT_APP_BASE_URL;
class JwtAuthService {
  /**
   * seting base url for api with no credentials
   */

  authClient = axios.create({
    baseURL: baseUrl,
  });

  user = {
    user: localStorageService.getItem("auth_user"),
    token: localStorage.getItem("jwt_token"),
    refreshToken:localStorage.getItem("refresh_token")
  }

 
  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then(data => {
      this.setSession(data.token);
      this.setUser(data.user);
      this.setRefreshToken(data.refreshToken)
      return data.user;
    });
  };
  
  setRefreshToken = (token) => {
    if (token) {
      localStorage.setItem("refresh_token", token);
    } else {
      localStorage.removeItem("refresh_token");
    }
  }

  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
    } else {
      localStorage.removeItem("jwt_token");
    }
  };

  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  }
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
