export const base64ToBlob = (base64) => {
    const byteData = base64.split(',')
    const byteString = atob(byteData[1]); // Decode Base64 string
    let mimeType = byteData[0]
    mimeType = mimeType.split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeType });
};
