import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoutes = () => {

  let auth = localStorage.getItem('jwt_token');

  return (
    auth ? <Outlet /> : <Navigate to='/live?invalid=true' />
  )
}
export default PrivateRoutes
