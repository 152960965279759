

const questionTypeEnum = {
    Multiple_choice_question: 1,
    Fill_in_the_blanks: 2,
    Subjective_question: 3,
    File_upload_type_question: 4,
    Multiple_correct_options: 5,
    Paragraph_type_question: 6
}

const quesDifficultyLevel = {
    Easy: 1,
    Medium: 2,
    Difficult: 3
}

const questionPickingEnum = {
    Auto: 1,
    Manual: 2,
}

/**
 * 
 * @param {*} level 
    @return {string} string of level
 */
const getDifficultyLevel = (level) => {
    switch (level) {
        case quesDifficultyLevel.Easy:
            return "Easy"
        case quesDifficultyLevel.Medium:
            return "Medium"
        case quesDifficultyLevel.Difficult:
            return "Difficult"
        default:
            return ''
    }
}
const getQuestionType= (type) => {
    switch (type) {
        case questionTypeEnum.Multiple_choice_question:
            return "Multiple choice question"
        case questionTypeEnum.Multiple_correct_options:
            return "Multiple correct options"
        case   questionTypeEnum.Paragraph_type_question:
            return "Paragraph type question"
        case questionTypeEnum.Subjective_question:
            return "Subjective question"
        case questionTypeEnum.Fill_in_the_blanks:
            return "Fill in the blanks"
        case questionTypeEnum.File_upload_type_question:
            return "File upload type question"
        default:
            return null
    }
}


const DificultyLevelOptions = [
    { value: `${quesDifficultyLevel.Easy}`, label: 'Easy' },
    { value: `${ quesDifficultyLevel.Medium}`, label: 'Medium' },
    { value: `${quesDifficultyLevel.Difficult}` , label: 'Difficult' }
]


const questionPickingOption = [
    { value: questionPickingEnum.Auto, label: "Auto" },
    { value: questionPickingEnum.Manual, label: "Manual" },

    
]
export {
    quesDifficultyLevel,
    questionTypeEnum,
    getDifficultyLevel,
    getQuestionType,
    DificultyLevelOptions,
    questionPickingOption,
    questionPickingEnum
}