import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import RootRoute, { privateRoutes, publicRoutes } from "app/RootRoute";
import PrivateRoutes from "./PrivateRoutes";

const feedbackProps = {
  text1: "Your exam has been submitted successfully.",
  text2: "Please close the window.",
};

const errorProps = {
  text1: "Sorry, Your exam has not been submitted successfully.",
  text2: "Please close the window.",
};

const LiveExamRouter = () => {
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    const handlePopState = () => {
      navigate(1); // Prevent going back
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  useEffect(() => {
    let recaptchIcon = document.getElementsByClassName('grecaptcha-badge')[0];

    if (location.pathname != "/live") {
      if (recaptchIcon) recaptchIcon.style.height = '0px';

    }
    else {
      if (recaptchIcon) recaptchIcon.style.height = '60px';
    }

  }, [location])
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        {
          privateRoutes.map((route, index) => {
            const Component = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  route.name === "Error" ? (
                    <Component message={errorProps} check={false} />
                  ) : route.name === "Feedback" ? (
                    <Component message={feedbackProps} check={true} />
                  ) : (
                    <Component />
                  )
                }
              />
            );
          })
        }
      </Route>
      {
        publicRoutes.map((route, index) => {
          const Component = route.component;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Component />
              }
            />
          );
        })
      }
      <Route
        path="*"
        element={<Navigate to="/live" replace />}
    />
    </Routes>
  );
};

export default LiveExamRouter;
