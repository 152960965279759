import { apiClient } from "./apiClient";
import axios from "axios";
// import {errorPNotify} from "utils"
// to upload byte array data
// used for screen shot image upload and camera capture
const getToken = async () => {
  return await localStorage.getItem("jwt_token");
};
const baseUrl = process.env.REACT_APP_BASE_URL;
const uploadMedia = async (files, bucketFolder) => {
  let headers = {
    Authorization: `Bearer ` + (await getToken()),
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let data = JSON.stringify(files);
  return await axios
    .post(`${baseUrl}/Common/upload-live-exam-capture/${bucketFolder}`, data, {
      headers,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
    })
    .catch((err) => {
      // errorPNotify(err.message)
    });
};

// to upload file type
// used for file upload type questions
const generalUploadMedia = async (files, bucketFolder, onUploadProgress) => {
  const formData = new FormData();
  formData.append("file", files);
  let config = {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress,
  };

  return await apiClient
    .post(`/Common/upload-file/${bucketFolder}`, formData, config)
    .then((res) => {
      if (res.data) {
        return res.data;
      } else {
        // errorPNotify("")
      }
    })
    .catch((err) => {
      // errorPNotify(err.message)
    });
};

export { uploadMedia, generalUploadMedia };
