import * as actionTypes from "../types";

//live exam initial sate 
const initialState = {
    question: {},
    answer: {},
    time: {
        hrs: 0,
        min: 0,
        sec: 0
    },
    countDownDate: new Date(),
    orderId: 1,
    totalQuestionCount: [],
    examSubmitting: false,
    examInfoDetails: {},
    examStart: false,
    loadingNext: false,
    loadingPrev: false,
    confirmExam: false,
    activityObject: {
        isRightClick: false,
        isCopyPaste: false,
        isOutofWindow: false,
        isPrintScreen:false,
        capturePath: '',
        captureDate: '',
        screenCaptured: ''
    },
    isCalculatorAllowed: false,
    showCalculator: false,
    isCaptureimage: false
    // liveSettings:
}

// live exam reducer

const LiveExamReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIVE_QUESTIONS:
            return {
                ...state,
                question: action.payload,
            }
        case actionTypes.SET_EXAM_ANSWER:
            return {
                ...state,
                answer: action.payload
            }
        case actionTypes.SET_TIME:
            return {
                ...state,
                time: {
                    ...state.time,
                    hrs: action.payload.hrs,
                    min: action.payload.min,
                    sec: action.payload.sec
                }
            }
        case actionTypes.SET_COUNTDOWN:
            return {
                ...state,
                countDownDate: action.payload,
            }
        case actionTypes.SET_ORDER_ID:
            return {
                ...state,
                orderId: action.payload,
            }
        case actionTypes.SET_QUESTION_COUNT:
            return {
                ...state,
                totalQuestionCount: action.payload,
            }
        case actionTypes.SET_EXAM_SUBMIT:
            return {
                ...state,
                examSubmitting: action.payload,
            }
        case actionTypes.SET_EXAM_START:
            return {
                ...state,
                examStart: action.payload,
            }
        case actionTypes.SET_EXAM_INFO:
            return {
                ...state,
                examInfoDetails: action.payload,
            }
        case actionTypes.SET_NEXT_LOADING:
            return {
                ...state,
                loadingNext: action.payload,
            }
        case actionTypes.SET_PREV_LOADING:
            return {
                ...state,
                loadingPrev: action.payload,
            }
        case actionTypes.SET_CONFIRM_EXAM:
            return {
                ...state,
                confirmExam: action.payload,
            }
        case actionTypes.SET_ACTIVITY_OBJECT:
            return {
                ...state,
                activityObject: action.payload
            }
        case actionTypes.SET_CALCULATOR_ALLOWED:
            return {
                ...state,
                isCalculatorAllowed: action.payload
            }
        case actionTypes.SET_CAPTURE_IMAGE_ALLOWED:
                return {
                    ...state,
                    isCaptureimage: action.payload
        }
        case actionTypes.SET_CALCULATOR_SHOW:
            return {
                ...state,
                showCalculator: action.payload
            }
        default:
            return state
    }
}

export default LiveExamReducer;
