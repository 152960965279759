//  Live Exam

export const SET_LIVE_QUESTIONS = "SET_LIVE_QUESTIONS";
export const SET_EXAM_ANSWER = "SET_EXAM_ANSWER";
export const SET_TIME = "SET_TIME";
export const SET_COUNTDOWN = "SET_COUNTDOWN";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_QUESTION_COUNT = "SET_QUESTION_COUNT";
export const SET_EXAM_SUBMIT = "SET_EXAM_SUBMIT";
export const SET_EXAM_START = "SET_EXAM_START";
export const SET_EXAM_INFO = "SET_EXAM_INFO";
export const SET_NEXT_LOADING = "SET_NEXT_LOADING";
export const SET_PREV_LOADING = "SET_PREV_LOADING";
export const SET_CONFIRM_EXAM = "SET_CONFIRM_EXAM";
export const SET_ACTIVITY_OBJECT = "SET_ACTIVITY_OBJECT";
export const SET_CALCULATOR_ALLOWED = "SET_CALCULATOR_ALLOWED";
export const SET_CAPTURE_IMAGE_ALLOWED = "SET_CAPTURE_IMAGE_ALLOWED";
export const SET_CALCULATOR_SHOW = "SET_CALCULATOR_SHOW";
