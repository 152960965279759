import liveExamApi from "app/services/api/liveExamApi";
import { generalUploadMedia } from "app/services/mediaUploadService";
import detectIncognito from "detectincognitojs";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { mediaTypeEnum } from "../../../LocalEnum/index";
import localStorageService from "../../services/localStorageService";


const LiveAuthContext = createContext({});
const LiveAuthProvider = (props) => {
  const navigate = useNavigate();
  const [loadComponent, setLoadComponent] = useState(false);
  const [formSave, setFormSave] = useState(false);
  const [formSaved, setFormSaved] = useState(false);
  const [examDetails, setExamDetails] = useState({});
  const [registrationForm, setRegistrationForm] = useState([]);
  const [examSettings, setExamSettings] = useState({});
  const [checkValid, setcheckValid] = useState(false);
  const [showInviteScreen, setShowInviteScreen] = useState(false);
  const [nameConfirmation, setNameConfirmation] = useState(false);
  const [nameConfirmationLoader, setNameConfimationLoading] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const [feedbackString, setFeedbackString] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [regFormDisabled, setRegFormDisabled] = useState(false);
  const [instDisabled, setInstDisabled] = useState(false);

  const handleChangeCandidateName = (e) => {
    setCandidateName(e.target.value);
  }
  const getSettingFromSessionStorage = () => {
    let examSetting = window.sessionStorage.getItem("examSetting");
    if (examSetting) {
      setExamSettings(JSON.parse(examSetting));
    }
  };
  const [isPermissions, setIsPermissions] = useState({
    video: false,
    audio: false,
    incognito: false,
  });

  const getVerified = async (examToken) => {
    await liveExamApi.authExamLink(examToken).then((res) => {
      if (res?.data) {
        //set local storage
        localStorage.clear();
        setSession(res.data.examInstructionsViewModel.authToken);
        setRefreshToken(res.data.examInstructionsViewModel.refreshToken);
        setUser(res.data.examInstructionsViewModel.candidateName);
        setUserEmail(res.data.examInstructionsViewModel.candidateEmail);
        setCaptureScreen(res.data.examInstructionsViewModel.screenCapture);
        setExamName(res.data.examName);
        setAvatar(res.data.examInstructionsViewModel.customerLogo);
        setTimer(res.data.examInstructionsViewModel.duration);
        setIntervalImage(res.data.examInstructionsViewModel.isCaptureImage);
        setIntervals(res.data.examInstructionsViewModel.captureInterval);
        setCalculatorAllow(res.data.examInstructionsViewModel.isCalculator);
        let Register = res.data.registrationForm;
        setRegistrationForm(Register);
        setcheckValid(true);
        setExamDetails(res.data);
        setLoadComponent(true);
      } else {
        toast.error(res.message)
        setcheckValid(false);
        setLoadComponent(true);
      }
    });
  };

  const getCandidateName = async (queryString) => {
    var uri_enc = encodeURIComponent(
      `${process.env.REACT_APP_LIVE_EXAM_URL}?${queryString}`
    );
    await liveExamApi.getCandidateName(uri_enc).then((res) => {
      setCandidateName(res.data.data)
    })
  }
  const handleConfirmName = async (e) => {
    e.preventDefault();
    setNameConfimationLoading(true)
    let queryString = window.location.search.split("?").pop();
    var uri_enc = encodeURIComponent(
      `${process.env.REACT_APP_LIVE_EXAM_URL}?${queryString}`
    );
    await liveExamApi.saveCandidateName(uri_enc, candidateName).then(async (res) => {
      if (res.data) {
        localStorage.setItem("candidateName", candidateName)

        toast.success("Name saved successfully.");
        if (
          examDetails.isExamForm &&
          !examDetails.examInstructionsViewModel.isExamStarted
        ) {
          navigate(`/registration?${queryString}`);
        } else if (
          (examDetails.examInstructionsViewModel.isCandidateImage || examDetails.examInstructionsViewModel.imageIDCapture) &&
          !examDetails.examInstructionsViewModel.isExamStarted
        ) {
          navigate(`/capture?${queryString}`);
        } else {
          await handleStartExam("");
        }
        setNameConfirmation(false);

      } else {
        toast.warn("Unable to save name. Please try again.")
      }
    }).finally(() => {
      setNameConfimationLoading(false)
    })
  }

  const settingExamDetails = async (examToken) => {
    await liveExamApi.authExamLink(examToken).then((res) => {
      if (res) {
        setExamDetails(res.data);
      }
    });
  };

  const setTimer = (dataTime) => {
    if (dataTime > 0) {
      window.sessionStorage.setItem("examduration", dataTime);
    } else {
      window.sessionStorage.removeItem("examduration");
    }
  };

  const setCalculatorAllow = (value) => {
    window.sessionStorage.setItem("isAllowCalculator", value);
  };
  const setCaptureScreen = (value) => {
    window.sessionStorage.setItem("screen_Capture", value);
  };
  const setIntervals = (value) => {
    window.sessionStorage.setItem("interval", value);
  };
  const setIntervalImage = (value) => {
    window.sessionStorage.setItem("is_capture_image", value);
  };

  const setExamName = (examName) => {
    if (examName) {
      localStorage.setItem("exam_name", examName);
    } else {
      localStorage.removeItem("exam_name");
    }
  };

  const setAvatar = async (avatar) => {
    if (avatar) {
      //var data = await liveExamApi.getBase64Image(avatar);
      localStorage.setItem("avatar", avatar);
    } else {
      localStorage.removeItem("avatar");
    }
  };

  const setRefreshToken = (token) => {
    if (token) {
      localStorage.setItem("refresh_token", token);
    } else {
      localStorage.removeItem("refresh_token");
    }
  };

  const setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
    } else {
      localStorage.removeItem("jwt_token");
    }
  };

  const setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  const setUserEmail = (user) => {
    localStorageService.setItem("auth_user_email", user);
  };

  const setUserImg = (user) => {
    localStorageService.setItem("auth_user_image", user);
  };

  const removeUser = () => {
    localStorage.removeItem("auth_user");
  };

  const handleRegistrationInput = (index) => async (e) => {
    let fileValue;
    if (registrationForm[index].type === 'file') {
      const newFile = e.target.files[0];
      if (newFile) {
        try {
          const res = await generalUploadMedia(newFile, mediaTypeEnum.LiveExamForm);
          fileValue = res;
        } catch (error) {
          console.error('File upload failed', error);
        }
      }
    }
    let temp = registrationForm.map((form, i) => {
      if (i === index) {
        return { ...form, value: registrationForm[index].type === 'file' ? fileValue : e.target.value };
      } else {
        return { ...form };
      }
    });

    setRegistrationForm(temp);
  };


  const handleRegistrationInputSelect = (index) => (e) => {
    let temp = registrationForm.map((form, i) => {
      if (i === index) {
        return { ...form, value: e.value };
      } else {
        return { ...form };
      }
    });
    setRegistrationForm(temp);
  };

  const InstructionCheck = (el, queryString) => {
    if (el != undefined && el != null) {
      var uri_enc = encodeURIComponent(
        `${process.env.REACT_APP_LIVE_EXAM_URL}?${queryString}`
      );
      const params = new URLSearchParams(queryString);
      const inviteCode = params.get("inv");
      if (!inviteCode) {
        setShowInviteScreen(true);
        setLoadComponent(true);
        return
      }
      getVerified(uri_enc);
    } else {
      setcheckValid(false);
      setLoadComponent(true);
    }
  };
  const incognitoCheck = async () => {
    const result = await detectIncognito();
    return result.isPrivate;
  };

  const InstructionAccept = async (params, queryString) => {
    const result = await incognitoCheck();
    if (
      (await requestCameraAccess()) &&
      (await requestAudioAccess()) &&
      isPermissions?.popup &&
      !result
    ) {
      // Proceed to the next page
      setBtnLoading(true);
      setInstDisabled(true);
      setNameConfirmation(true)
      setBtnLoading(false);
      let recaptchIcon = document.getElementsByClassName('grecaptcha-badge')[0];
      if (recaptchIcon) recaptchIcon.style.height = '0px';

    } else {
      // Prevent navigation and display an error message
      toast.error("System is not compatible to take the assessment. Kindly fix to take the assessment.");
    }
  };

  async function requestCameraAccess() {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      // Camera access granted, handle the stream or perform other actions
      return true;
    } catch (error) {
      return false;
    }
  }

  async function requestAudioAccess() {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Camera access granted, handle the stream or perform other actions
      return true;
    } catch (error) {
      return false;
    }
  }

  const handleStartExam = async (newData) => {
    var ip = "";
    await liveExamApi.getIP().then((data) => (ip = data?.ip));
    const browser = await liveExamApi.browserDetect();
    const os = await liveExamApi.detectOperatingSystem();
    let data = {
      capturedImage: newData,
      ipAddress: ip,
      operatingSystem: os,
      browser: browser,
    };

    await liveExamApi
      .startExam(data)
      .then((data) => {
        if (data.data) {
          window.sessionStorage.setItem("orderId", data.orderId);
          window.sessionStorage.setItem(
            "isAllowCalculator",
            data.isAllowCalculator
          );
          handleNewWindow();
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        toast.error(
          "There is some issue in starting the assessment. Please try again."
        );
      });
  };

  const popup = async () => {
    const popup = window.open("", "popup", "width=1,height=1");
    console.log(popup);
    if (popup) {
      popup.close();
      setIsPermissions((prev) => {
        let prevPermissions = prev;
        return { ...prevPermissions, popup: true };
      });
      return true;
    } else {
      setIsPermissions((prev) => {
        let prevPermissions = prev;
        return { ...prevPermissions, popup: false };
      });
      return false;
    }
  };
  async function requesting() {
    if (await requestCameraAccess()) {
      setIsPermissions((prev) => {
        let prevPermissions = prev;
        return { ...prevPermissions, video: true };
      });
    }
    if (await requestAudioAccess()) {
      setIsPermissions((prev) => {
        let prevPermissions = prev;
        return { ...prevPermissions, audio: true };
      });
    }
  }
  const firstIncognitoCheck = () => {
    incognitoCheck().then((isPrivate) => {
      if (isPrivate) {
        setIsPermissions((prev) => {
          let prevPermissions = prev;
          return { ...prevPermissions, incognito: true };
        });
        toast.error("You are not allowed to take assessment in incognito/private mode.");
      }
    });
  };

  //open new window for live exam
  const handleNewWindow = () => {
    var x = window.screen.width;
    var y = window.screen.height;
    let newWin = window.open(
      `${window.origin}/live-exam`,
      "",
      `width=${x},height=${y},
             toolbar=no,
             scrollbars=no,
             location=no,
             resizable=no,
             fullscreen=yes,
             dialog=yes,
             top=0,left=0,status=1`
    );
    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      toast.error("Allow pop-ups to take assessment.");
    }
  };

  const checkRegistrationInputs = () => {
    for (let i = 0; i < registrationForm.length; i++) {
      if (
        registrationForm[i].isMandatory == true &&
        registrationForm[i].value == ""
      ) {
        return false;
      }
    }
    return true;
  };

  const handleRegistrationFormSubmit = async (params, query) => {
    setFormSave(true);
    if (!checkRegistrationInputs()) {
      setFormSave(false);
      toast.info("Please fill all required field(s)");
      setRegFormDisabled(false);
      return;
    }
    setRegFormDisabled(true);
    let data = {
      ...examDetails,
      registrationForm: registrationForm,
    };
    await liveExamApi
      .registerUser(data)
      .then((res) => {
        setRegistrationForm([]);
        setExamSettings(res);
        setFormSave(false);
        setFormSaved(true);
        if (examDetails.examInstructionsViewModel.isCandidateImage || examDetails.examInstructionsViewModel.imageIDCapture) {
          navigate(`/capture?${query}`);
        } else {
          handleStartExam("");
        }
      })
      .catch((err) => {
        setFormSaved(false);
        toast.error("Registration form was not saved. Please try again.");
      });
  };

  const handleChangeFeedback = (value) => {
    setFeedbackString(value);
  };

  const handleFeedbackPage = async () => {
    let data = {
      feedback: feedbackString,
    };
    await liveExamApi.submitFeedBack(data).then((res) => {
      if (res) {
        liveExamApi.logoutUser().then(() => {
          removeUser();
        });

        navigate("/feedback");
        // window.location = "https://disamina.in"
      } else {
        navigate("/error");
      }
    });
  };

  const getFromDetails = async (data) => {
    await liveExamApi.examFormDetails(data).then((res) => {
      if (res && res.length > 0) {
        setRegistrationForm(res);
      }
    });
  }

  const registrationAction = {
    handleRegistrationInput,
    handleRegistrationFormSubmit,
    InstructionCheck,
    InstructionAccept,
    handleNewWindow,
    handleFeedbackPage,
    handleChangeFeedback,
    handleRegistrationInputSelect,
    settingExamDetails,
    handleConfirmName,
    handleChangeCandidateName,
    getCandidateName,
    requestAudioAccess,
    requestCameraAccess,
    incognitoCheck,
    popup,
    setIsPermissions,
    firstIncognitoCheck,
    requesting,
    setUserImg,
    getFromDetails,
  };

  return (
    <LiveAuthContext.Provider
      value={{
        examSettings,
        registrationForm,
        loadComponent,
        formSave,
        formSaved,
        registrationAction,
        checkValid,
        nameConfirmation,
        nameConfirmationLoader,
        candidateName,
        showInviteScreen,
        examDetails,
        feedbackString,
        btnLoading,
        regFormDisabled,
        instDisabled,
        isPermissions,
      }}
    >
      {props.children}
    </LiveAuthContext.Provider>
  );
};

export { LiveAuthContext, LiveAuthProvider };
