import React from 'react';
const StudentRegistration = React.lazy(() => import('app/views/StudentRegistration'));
const InstructionPage = React.lazy(() => import('app/views/StudentRegistration/components/InstructionPage'));
const ExamDetails = React.lazy(() => import('app/views/ExamDetails'));
const LiveExam = React.lazy(() => import('app/views/LiveExam'));
const FeedBack = React.lazy(() => import('app/views/FeedBack/index'))
const SubmitFeed = React.lazy(() => import('app/views/FeedBack/SubmitFeed'));

export const privateRoutes = [
    {
        path: "/registration",
        name: "Student Registration",
        component: StudentRegistration,
    },
    {
        path: "/capture",
        name: "Image capture",
        component: ExamDetails,
    },
    {
        path: "/live-exam",
        name: "Live exam",
        component: LiveExam,
    },
    {
        path: "/feedback",
        name: "Feedback",
        component: FeedBack,
    },
    {
        path: "/error",
        name: "Error",
        component: FeedBack,
    },
    {
        path: "/submit-feedback",
        name: "Submit Feedback",
        component: SubmitFeed,
    },
];
export const publicRoutes = [
    {
        path: "/live",
        name: "Instruction Page",
        component: InstructionPage,
    },
]
