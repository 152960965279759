import React from "react"
import { Spinner } from "react-bootstrap"

const PageLoader = () => {
    return <div className="page-loader" >
        <div>
            <Spinner animation="border" variant="primary" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>

    </div>


}

export default PageLoader